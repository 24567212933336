<template>
    <b-form-group>
        <label>Tipo</label>
        <multiselect
            id="type"
            label="label"
            track-by="value"
            v-model="type"
            :options="typeOptions"
            :showLabels="false"
            :searchable="false"
            :allowEmpty="true"
            placeholder="Selecionar"
            class="with-border"
        >
            <template slot="caret">
                <div class="chevron">
                    <ChevronDown />
                </div>
            </template>
            <template slot="noOptions"> Nenhuma opção </template>
            <template slot="noResult"> Nenhum resultado </template>
        </multiselect>
        <b-form-invalid-feedback :state="!validated ? null : !!type">Campo obrigatório</b-form-invalid-feedback>
    </b-form-group>
</template>
<script>
import * as ITEM from '@items/utils/constants'

export default {
    props: {
        groupType: String,
        value: String,
        validated: Boolean,
        grouped: Boolean
    },
    components: {
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    },
    data() {
        const excludes = []
        if (this.grouped) {
            excludes.push(ITEM.ITEM_TYPE_APPOINTMENT)
            excludes.push(ITEM.ITEM_TYPE_EXAM)
            excludes.push(ITEM.ITEM_TYPE_SURGICAL)
            excludes.push(ITEM.ITEM_TYPE_RETURN)
            excludes.push(ITEM.ITEM_TYPE_TELEMEDICINE)
        }

        return {
            type: null,
            typeOptions: ITEM.ITEM_TYPE_OPTIONS
                .filter(option => ITEM.getByTypesByGroup(this.groupType).includes(option.value))
                .filter(option => !excludes.includes(option.value))
                .filter(option => !ITEM.notImplementedTypes.includes(option.value)
            ),
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(value) {
                this.type = this.typeOptions.find(option => option.value === value)
            }
        },
        type(option) {
            this.$emit('input', option?.value)
            this.$emit('change', option?.value)
        }
    }
}
</script>
<style lang="scss" scoped>

</style>